import {getRequest,postRequest,deleteRequest} from "../utils/request"

export default {
    getUrloConfigTable(){
        return getRequest(`/api/urlconf/tablelist`)
    },
    saveUrlConfig(params,status){
        return postRequest(`/api/urlconf/${status}`,params)
    },
    getConfInfosById(id){
        return getRequest(`/api/urlconf/getOneConf/${id}`)
    },
    deleteConfUrl(id){
        return deleteRequest(`/api/urlconf/deleteConfUrl/${id}`)
    },
}