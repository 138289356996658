<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-button type="primary" @click="dialogVisible = true" size="medium"
              >配置映射</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      border
      style="width: 100%"
      stripe
      size="small"
      :row-style="{ height: '10px' }"
      :cell-style="{ padding: '1px 0' }"
    >
      <el-table-column prop="id" label="编号" width="50" align="center"></el-table-column>
      <el-table-column
        prop="code"
        label="code"
        width="220"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="url"
        label="连接url"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="primurl"
        label="映射url"
        width="400px"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="note"
        width="200"
        label="描述"
        show-overflow-tooltip
        align="center"
      ></el-table-column>

      <el-table-column
        fixed="right"
        prop="icon"
        width="220px"
        label="操作"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="editHandler(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm title="确认删除？" @confirm="delHandle(scope.row.id)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!--配置映射关系-->
    <el-dialog
      title="配置映射关系"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="code码" prop="code" label-width="100px">
          <div class="codeinfo">
            <el-input v-model="editForm.code" autocomplete="off"></el-input>
            <el-button type="primary" plain @click="randomCode">随机一个</el-button>
          </div>
        </el-form-item>
        <el-form-item label="映射url" prop="primurl" label-width="100px">
          <el-input v-model="editForm.primurl" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="note" label-width="100px">
          <el-input v-model="editForm.note" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('editForm')">保存</el-button>
        <el-button @click="resetForm('editForm')">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import conf from "../../api/urlconf";
export default {
  name: "Dicts",
  data() {
    let isTrueUrl = (rule, value, callback) => {
      let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的链接"));
      } else {
        callback();
      }
    };
    return {
      tableData: [],

      dialogVisible: false,

      total: 10,
      size: 10,
      current: 1,

      editForm: {},
      editFormRules: {
        code: [{ required: true, message: "code码", trigger: "blur" }],
        primurl: [
          { required: true, message: "请选输入自定义链接", trigger: "blur" },
          { validator: isTrueUrl, trigger: "blur" },
        ],
        note: [{ required: true, message: "请输入描述", trigger: "blur" }],
      },
    };
  },
  created() {
    this.gettableList();
  },
  methods: {
    gettableList() {
      conf.getUrloConfigTable().then((res) => {
        this.tableData = res.data.data.result.records;
        this.total = res.data.data.result.total;
        this.size = res.data.data.result.size;
        this.current = res.data.data.result.current;
      });
    },
    handleSizeChange(val) {
      this.size = val;
    },
    handleCurrentChange(val) {
      this.current = val;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },
    //随机一个code
    randomCode() {
      let chars = "ABCDEFGHJKMNPQRSTWXYZ2345678";
      let maxPos = chars.length;
      var code = "";
      for (let i = 0; i < 12; i++) {
        code += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      this.$set(this.editForm, "code", code);
    },
    submitForm(formName) {
      let status = this.editForm.id ? "update" : "save";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          conf.saveUrlConfig(this.editForm, status).then((res) => {
            if (res.data.data.result) {
              this.$notify({
                title: "映射成功",
                position: "bottom-right",
                type: "success",
              });
            }
            this.gettableList();
            this.resetForm("editForm");
          });
        } else {
          return false;
        }
      });
    },
    editHandler(id) {
      this.dialogVisible = true;
      conf.getConfInfosById(id).then((res) => {
        this.editForm = res.data.data.result;
      });
    },
    delHandle(id) {
      conf.deleteConfUrl(id).then((res) => {
        this.gettableList();
      });
    },
  },
};
</script>

<style scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.card-contanier {
  margin-bottom: 5px;
}
.codeinfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
